<template>
  <div class="contract-plan-list">
    <PageTitle :title="$t('page.contractSetting.title')" hideBtn />
    <section>
      <BaseTable v-loading="loading" :data="displayData" :empty-text="$t('common.table.empty.text')">
        <EmptyBlock slot="empty" />
        <BaseElTableColumn :label="$t('contractSetting.table.name.title')" prop="name" align="center" fixed="left" />
        <BaseElTableColumn :label="$t('contractSetting.table.shopLimit.title')" prop="shopLimit" align="center" />
        <BaseElTableColumn :label="$t('contractSetting.table.shopCount.title')" prop="shopCount" align="center" />
        <BaseElTableColumn :label="$t('contractSetting.table.modules.title')" prop="modules" align="center" width="300" />
        <BaseElTableColumn :label="$t('contractSetting.table.contractPeriod.title')" prop="contractPeriod" align="center" />
      </BaseTable>

      <Pagination
        :curPage.sync="tableOptions.page"
        :pageLimit="tableOptions.pageLimit"
        :total="contractCount"
        @pageChange="changePage"
      />
    </section>
  </div>
</template>

<script>
import { moduleText } from '@/config/contractPlan'
import EmptyBlock from '@/components/EmptyBlock.vue'
import { GetContractPlan, GetContractPlanCount } from '@/api/contract'
import { get, map } from 'lodash'
import { defineComponent, ref, reactive, computed, onMounted } from 'vue'
// Utils
import { pageStartIndex } from '@/utils/table'
import { formatDate } from '@/utils/date'
import { i18n } from '@/plugins/i18n/i18n'

export default defineComponent( {
  name: 'ContractPlanList',
  components: { EmptyBlock },
  setup () {
    const loading = ref(false)
    const selectRow = ref(null)
    const tableOptions = reactive({
      page: 1,
      pageLimit: 10,
    })
    const contractPlanList = ref([])
    const contractCount = ref(0)
    const pageStart = computed(() => pageStartIndex(tableOptions.page, tableOptions.pageLimit))
    const displayData = computed(() => map(contractPlanList.value, one => ({
        ...one,
        contractPeriod: `${formatDate(get(one.Contract, 'start'), 'YYYY/MM/DD')} ${i18n.t('common.input.datepicker.rangeSeparator')} ${formatDate(get(one.Contract, 'end'), 'YYYY/MM/DD')} (Asia/Taipei)`,
        modules: Object.keys(one).map(key => {
          if (one[key] === true && moduleText[key]) {
            return i18n.t(moduleText[key])
          }
          return null
        }).filter(one => one !== null).join('、'),
      }))
    )
    //= > 取得合約
    const getContractPlan = async () => {
      try {
        contractPlanList.value = await GetContractPlan({ start: pageStart.value, limit: tableOptions.pageLimit })
      } catch (error) {
        console.log(error)
        window.$message.error({
          message: error || error.message,
        })
      }
    }
    const renderModules = (row) => {
      return Object.keys(row).map(key => {
        if (row[key] === true && moduleText[key]) {
          return i18n.t(moduleText[key])
        }
        return null
      }).filter(one => one !== null).join('、')
    }
    //= > 取得總店家數量
    const getContractPlanCount = async () => {
      try {
        contractCount.value = await GetContractPlanCount({})
      } catch (error) {
        console.log(error)
        window.$message.error({
          message: error || error.message,
        })
      }
    }
    const changePage = async () => {
      await refresh()
    }
    const refresh = async () => {
      await Promise.all([getContractPlan(), getContractPlanCount()])
    }

    onMounted(async () => {
      await refresh()
    })

    return {
      loading,
      selectRow,
      tableOptions,
      contractCount,
      changePage,
      displayData,
    }
  },
})
</script>

<style scoped lang="scss"></style>
